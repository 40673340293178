<template>
  <div class="tender-month-stat">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="reportDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyyMMdd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <my-spread ref="mySpread"
      v-if="defaultDataList.length"
      :default-data-list="defaultDataList"
      :spread-info="spreadInfo">
    </my-spread>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'
import MySpread from '@/components/Spread/MySpread'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import TenderMonthStatSpreadInfo from '@/views/MeterageDateStatManage/TenderMonthStatSpreadInfo'

export default {
  name: 'SectionDateStat',
  components: {
    MySpread,
    MySearchBar
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: JSON.parse(JSON.stringify(TenderMonthStatSpreadInfo)),
      year: '',
      month: '',
      reportDate: ''
    }
  },
  watch: {
    reportDate (newVal) {
      if (newVal) {
        this.year = parseInt(this.reportDate.substr(0, 4))
        this.month = parseInt(this.reportDate.substr(4, 2))
        this.defaultDataList = []
        this.initSpreadInfo()
        this.getData()
      }
    }
  },
  methods: {
    initSpreadInfo () {
      this.spreadInfo = JSON.parse(JSON.stringify(TenderMonthStatSpreadInfo))
      const column = {
        label: `标段合计`,
        children: [
          {
            label: '月计划',
            dataKey: 'currentMonthPlanNum',
            width: '100'
          },
          {
            label: '月完成',
            dataKey: 'currentMonthStatNum',
            width: '100'
          }
        ]
      }
      this.spreadInfo.columns.push(column)
    },
    getData () {
      axios.get(utility.getRestFullUrl('meterageTenderMonthStat'), {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: this.year,
          month: this.month
        }
      })
        .then(res => {
          res.data.data.sections.forEach(section => {
            const column = {
              label: section.tenderName + '工区',
              children: [
                {
                  label: '月计划',
                  dataKey: `plan_${section.tenderGuid}`,
                  width: '100'
                },
                {
                  label: '月统计',
                  dataKey: `stat_${section.tenderGuid}`,
                  width: '100'
                }
              ]
            }
            this.spreadInfo.columns.push(column)
          })
          const list = res.data.data.tenderMonthDisplayList.map(monthDisplayItem => {
            res.data.data.monthStatList
              .filter(monthItem => monthItem.meterageCode === monthDisplayItem.meterageCode)
              .forEach(monthItem => {
                monthDisplayItem[`plan_${monthItem.tenderGuid}`] = monthItem.currentMonthPlanNum
                monthDisplayItem[`stat_${monthItem.tenderGuid}`] = monthItem.currentMonthStatNum
              })
            return monthDisplayItem
          })
          this.defaultDataList = list
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.tender-month-stat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
