const TenderMonthStatSpreadInfo = {
  sheetName: '月统计',
  columns: [
    {
      label: '子目号',
      dataKey: 'meterageDisplayCode',
      width: '110'
    },
    {
      label: '子目名称',
      dataKey: 'meterageName',
      width: '150'
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '清单数量',
      dataKey: 'pictureNum',
      width: '100'
    },
    {
      label: '单价',
      dataKey: 'price',
      width: '100'
    },
    {
      label: '金额',
      dataKey: 'pictureAmount',
      width: '100'
    }
  ]
}

export default TenderMonthStatSpreadInfo
